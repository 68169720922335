import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Routes, Route, Navigate, useParams, Link, useLocation } from "react-router-dom"
import Select from 'react-select'

import { APIError, useAuth } from "../../../../app/modules/auth"
import { Account, AvailableFRMLocation, getAllFRMLocationsForAccountUniqueIdentifier, getPassareOrganizationByUniqueIdentifier, PassareOrganization, selectFRMLocationForPassareOrganizationByUniqueIdentifier } from "../../../core/_requests"
import { PageTitle } from "../../../../_metronic/layout/core"
import MogulHeader from "../../../components/MogulHeader"
import { useFrmAuthenticationURL } from "../../../hooks/useFrmAuthenticationURL"
import useAccounts from "../../../hooks/useAccounts"

type PassareOrganizationDetailContextProps = {
    passareOrganization: PassareOrganization
}

const PassareOrganizationDetailContext = createContext<PassareOrganizationDetailContextProps>({
    passareOrganization: {} as PassareOrganization,
})

export const usePassareOrganizationDetail = () => {
    return useContext(PassareOrganizationDetailContext)
}

const GetAvailableLocationsGivenAnAccount = ({
    passareOrganization,
    account
}: {
    passareOrganization: PassareOrganization
    account: Account
}) => {

    const [loadingAvailableLocations, setLoadingAvailableLocations] = useState(true)
    const [errorLoadingAvailableLocations, setErrorLoadingAvailableLocations] = useState<string | null>(null)
    const [availableServiceLocations, setAvailableServiceLocations] = useState<AvailableFRMLocation[]>([])

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>(passareOrganization.private_data.frm_linkage_information?.frm_location_id || '')
    const [errorLinkingToFRM, setErrorLinkingToFRM] = useState<string | null>(null)

    const tryToGetAllFRMLocationsForAccountUniqueIdentifier = useCallback(async () => {
        setLoadingAvailableLocations(true)
        try {
            const allFRMLocations = await getAllFRMLocationsForAccountUniqueIdentifier(account.unique_identifier)
            setAvailableServiceLocations(allFRMLocations)
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load available locations'
            setErrorLoadingAvailableLocations(errorMessage)
        } finally {
            setLoadingAvailableLocations(false)
        }
    }, [account.unique_identifier])

    const handleSelectFRMLocationIdForServiceLocationByUniqueIdentifier = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await selectFRMLocationForPassareOrganizationByUniqueIdentifier({
                passare_organization_unique_id: passareOrganization.unique_identifier,
                account_unique_id: account.unique_identifier,
                frm_location_id: selectedLocationId
            })
            window.location.reload()
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to link Passare organization to FRM'
            setErrorLinkingToFRM(errorMessage)
        }
    }, [selectedLocationId, account.unique_identifier])

    const linkOutToFRMWithURL = useMemo(() => {
        //
        // Right now service locations don't have GHL details because it's an admin detail not added to that
        // retrieval. The quickest fix right now is looking up the admin account mapping
        // and deducing the URL from that.
        //
        if (!account) {
            return null
        }
        if (account.external_ghl_site_url) {
            return account.external_ghl_site_url
        }
        return null
    }, [account])

    useEffect(() => {
        tryToGetAllFRMLocationsForAccountUniqueIdentifier()
    }, [tryToGetAllFRMLocationsForAccountUniqueIdentifier])

    if (loadingAvailableLocations) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingAvailableLocations) {
        return <div className='mt-5'>
            <div className="alert alert-danger" role="alert">
                {errorLoadingAvailableLocations}
            </div>
        </div>
    }

    return <div className='mb-5'>
        {availableServiceLocations.length === 0 ? <div className='mt-5'>
            <div className="alert alert-info" role="alert">
                No available locations
            </div>
        </div> : <div className='mt-5'>
            <h2>
                Select a location:
            </h2>
            <form className='mt-5' onSubmit={handleSelectFRMLocationIdForServiceLocationByUniqueIdentifier}>
                <Select
                    required
                    options={[
                        { value: '', label: '- Leave un-mapped -' },
                        ...availableServiceLocations.map((availableServiceLocation) => {
                            return {
                                value: availableServiceLocation._id,
                                label: `${availableServiceLocation.name}`
                            }
                        })]}
                    onChange={(selectedOption) => {
                        setSelectedLocationId(selectedOption ? selectedOption.value : null)
                    }}
                    value={availableServiceLocations.filter(x => x._id === selectedLocationId).map((availableServiceLocation) => {
                        return {
                            value: availableServiceLocation._id,
                            label: `${availableServiceLocation.name}`
                        }
                    })}
                    filterOption={(option, searchValue) => {
                        const words = searchValue.toLowerCase().split(' ');
                        return words.every(word => option.label.toLowerCase().includes(word));
                    }}
                />
                <div>
                    <button
                        className='btn btn-primary btn-lg mt-5'
                        type='submit'
                        disabled={!selectedLocationId ? true : false}
                    >
                        {`Link to ${account.name}${selectedLocationId ? ` and ${availableServiceLocations.find(x => x._id === selectedLocationId)?.name}` : ''}`}
                    </button>
                </div>
            </form>
        </div>}
        {errorLinkingToFRM && <div className='mt-5'>
            <div className='separator separator-dashed my-5'></div>
            <div className="alert alert-danger" role="alert">
                {errorLinkingToFRM}
            </div>
        </div>}
        {selectedLocationId && <div>
            <div className='separator separator-dashed my-5'></div>
            {/* /v2/location/IQzMIO91nK1KTq0PCwq8/contacts/smart_list/All */}
            FRM Location ID: <a href={`${linkOutToFRMWithURL}/v2/location/${selectedLocationId}/contacts/smart_list/All`} target='_blank' rel='noreferrer'>
                {selectedLocationId}
            </a>
        </div>}
    </div>
}

function PassareOrganizationDetail() {

    const { passare_organization_unique_identifier: passareOrganizationUniqueIdentifier } = useParams<{
        passare_organization_unique_identifier: string
    }>()

    const {
        currentUser
    } = useAuth()

    const { pathname } = useLocation();

    const [passareOrganization, setPassareOrganization] = useState<PassareOrganization | null>(null);
    const [loadingPassareOrganizations, setLoadingPassareOrganization] = useState(true);
    const [errorLoadingPassareOrganizations, setError] = useState<string | null>(null);

    const [viewingTab, setViewingTab] = useState<'events' | 'events_administrators' | 'events_check_in_records' | 'settings'>('events')

    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)

    const {
        accounts,
        loadingAccounts,
    } = useAccounts()

    const loadPassareOrganization = useCallback(async () => {
        if (!passareOrganizationUniqueIdentifier) {
            return
        }
        if (loadingAccounts) {
            return
        }
        setLoadingPassareOrganization(true)
        setError(null)
        try {
            const updatedPassareOrganization = await getPassareOrganizationByUniqueIdentifier(passareOrganizationUniqueIdentifier)
            setPassareOrganization(updatedPassareOrganization)
            console.log(updatedPassareOrganization)
            if (updatedPassareOrganization.private_data.frm_linkage_information?.account_unique_identifier) {
                const selectedAccount = accounts.find(account => account.unique_identifier === updatedPassareOrganization.private_data.frm_linkage_information?.account_unique_identifier)
                setSelectedAccount(selectedAccount || null)
            }
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load Passare organization'
            setError(errorMessage)
        } finally {
            setLoadingPassareOrganization(false)
        }
    }, [passareOrganizationUniqueIdentifier, loadingAccounts, accounts])

    useEffect(() => {
        loadPassareOrganization()
    }, [loadPassareOrganization])

    const frmAuthenticationURL = useFrmAuthenticationURL({
        accountUniqueId: selectedAccount ? selectedAccount.unique_identifier : undefined
    })

    if (loadingPassareOrganizations || loadingAccounts) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingPassareOrganizations || !passareOrganization) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorLoadingPassareOrganizations || 'Failed to load Passare organization (501)'}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadPassareOrganization}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <PassareOrganizationDetailContext.Provider value={{
        passareOrganization,
    }}>
        <MogulHeader title={`${passareOrganization.organization_name}`} />
        <div className='mb-5'>
            <h1 className='d-inline'>
                {`Link ${passareOrganization.organization_name} to one of the following accounts:`}
            </h1>
        </div>
        <select className='form-select form-select-lg mb-5' onChange={(e) => {
            const selectedAccount = accounts.find(account => account.unique_identifier === e.target.value)
            setSelectedAccount(selectedAccount || null)
        }} value={selectedAccount ? (selectedAccount as Account)?.unique_identifier : ''}>
            <option value='' disabled>Select an account</option>
            {accounts.map((account) => (
                <option key={account.unique_identifier} value={account.unique_identifier}>
                    {account.name}
                </option>
            ))}
        </select>
        {!selectedAccount ? <>
            {/* Nothing here yet */}
        </> : <>
            {!selectedAccount.private_data?.linked_to_frm_company_id ? <>
                <h3>
                    The selected account is not linked to a FRM company.
                </h3>
                <h3>
                    <Link to={`/accounts/${selectedAccount.unique_identifier}/admin`}>
                        Please link the selected account to a FRM company before proceeding.
                    </Link>
                </h3>
            </> : <>
                <GetAvailableLocationsGivenAnAccount
                    passareOrganization={passareOrganization}
                    account={selectedAccount}
                />
            </>}
        </>}
    </PassareOrganizationDetailContext.Provider >

}

export default PassareOrganizationDetail