/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, createContext, useContext } from 'react'
import {
  DateTime
} from 'luxon'

import {
  Account,
  getAllEventAdministratorLeads, DashboardEventAdministratorsData,
  getAllEventCheckInRecords, DashboardEventCheckInRecordsData,
  getAllDirectMailReplies, DashboardDirectMailRepliesData,
  syncLinkManuallyByUniqueIdentifier,
} from '../../../../mogul/core/_requests'
import { APIError, useAuth } from '../../../../app/modules/auth'
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import useAccounts from '../../../../mogul/hooks/useAccounts'
import MogulHeader from '../../../../mogul/components/MogulHeader'
import { FilterAllLeads } from './FilterAllLeads'
import { PassareAcquaintancesTable } from './lead-tables/PassareAcquaintancesTable'
import { formatLeadItemDate } from './DebugPipedrive'
import { FullCircleLeadsTable } from './lead-tables/FullCircleLeadsTable'
import { AbandonedCartsTable } from './lead-tables/AbandonedCartsTable'

type LeadDetailContextProps = {
  accounts: Account[],
}

const LeadDetailContext = createContext<LeadDetailContextProps>({
  accounts: [],
})

export const useLeadDetail = () => {
  return useContext(LeadDetailContext)
}

export function SyncLeadButton({ uniqueIdentifier, disabled = false }: { uniqueIdentifier: string, disabled?: boolean }) {

  const [syncing, setSyncing] = useState(false)
  const [syncError, setSyncError] = useState<string | null>(null)

  const onSyncClicked = useCallback(async (syncUniqueIdentifier: string) => {
    setSyncing(true)
    setSyncError(null)
    // todo
    try {
      await syncLinkManuallyByUniqueIdentifier(syncUniqueIdentifier)
      // for now, reload
      window.location.reload()
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to sync'
      console.error(errorMessage)
      setSyncError(errorMessage)
      setSyncing(false)
    }
  }, [])

  return <div>
    <div>
      {syncError && <div className='alert alert-danger' role='alert'>
        {syncError}
      </div>}
    </div>
    <button className='btn btn-primary btn-sm' disabled={disabled || syncing} onClick={() => onSyncClicked(uniqueIdentifier)}>
      Sync to 360
    </button>
  </div>
}

export function LeadHeader({
  title,
  subtitle,
  showLeadsRegardlessofGHLStatus,
  setShowLeadsRegardlessofGHLStatus,
  setFilteringByLocation,
}: {
  title: string,
  subtitle?: string,
  showLeadsRegardlessofGHLStatus: boolean,
  setShowLeadsRegardlessofGHLStatus: (value: boolean) => void,
  setFilteringByLocation: (locationUniqueIdentifier: string | null) => void,
}) {

  const [viewingFilter, setViewingFilter] = useState(false)

  return <>
    {/* begin::Header */}
    <div className='card-header align-items-center border-0 mt-3'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='fw-bolder text-dark fs-3'>
          {title}
        </span>
        {subtitle && <span className='text-gray-400 mt-2 fw-bold fs-6'>{subtitle}</span>}
      </h3>
      <div className='card-toolbar'>
        {/* begin::Menu */}
        <button
          type='button'
          className='btn btn-sm btn-light me-2'
          onClick={() => setViewingFilter(!viewingFilter)}
        >
          <span className='indicator-label'>
            Filter List
          </span>
        </button>
        {/* end::Menu */}
      </div>
    </div>
    {/* end::Header */}
    {viewingFilter && <>
      <FilterAllLeads
        onCancel={() => setViewingFilter(false)}
        onApply={setFilteringByLocation}
        showLeadsRegardlessofGHLStatus={showLeadsRegardlessofGHLStatus}
        setShowLeadsRegardlessofGHLStatus={setShowLeadsRegardlessofGHLStatus}
      />
    </>}
  </>

}

function EventAdministratorTable() {

  const {
    currentUser
  } = useAuth()

  const {
    accounts
  } = useLeadDetail()

  const isAdmin = !!currentUser?.is_admin

  const [showLeadsRegardlessofGHLStatus, setShowLeadsRegardlessofGHLStatus] = useState(isAdmin ? false : true)

  const [filteringByLocationUniqueIdentifier, setFilteringByLocationUniqueIdentifier] = useState<string | null>(null)

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState<string | null>(null);

  const [dashboardResponse, setDashboardResponse] = useState<DashboardEventAdministratorsData | null>(null)

  const loadList = useCallback(async () => {
    // We reload on viewingTab for now
    setErrorLoading(null)
    setLoading(true)
    try {
      const result = await getAllEventAdministratorLeads({
        limit: 100,
        showLeadsRegardlessofGHLStatus,
        filteringByLocationUniqueIdentifier
      })
      setDashboardResponse(result)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load leads'
      setErrorLoading(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [filteringByLocationUniqueIdentifier, showLeadsRegardlessofGHLStatus])

  useEffect(() => {
    loadList()
  }, [loadList])

  if (loading) {
    return <div className='p-10'>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  if (errorLoading) {
    return <div className='p-10'>
      <div className="alert alert-danger" role="alert">
        {errorLoading}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={loadList}>
          Retry
        </button>
      </div>
    </div>
  }

  return <div>
    <LeadHeader
      title={dashboardResponse?.total_event_administrators === 1 ? '1 Event Administrator' : `${dashboardResponse?.total_event_administrators} Event Administrators`}
      subtitle={!isAdmin ? '' : 'Only showing leads that have not been synced to the 360'}
      showLeadsRegardlessofGHLStatus={showLeadsRegardlessofGHLStatus}
      setShowLeadsRegardlessofGHLStatus={setShowLeadsRegardlessofGHLStatus}
      setFilteringByLocation={setFilteringByLocationUniqueIdentifier}
    />
    <div className='px-10'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-150px'>When Created?</th>
            {isAdmin && <th className='min-w-150px'>Account</th>}
            <th className='min-w-150px'>Partner</th>
            <th className='min-w-150px'>Location</th>
            <th className='min-w-150px'>Event</th>
            <th className='min-w-150px'>Person</th>
            <th className='min-w-150px'>FRM</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {dashboardResponse?.event_administrators.map((eventAdministrator) => {

            let linkOutToFRMWithURL;
            try {
              if (eventAdministrator.public_data.latest_highlevel_contact_id && eventAdministrator.location?.private_data?.linked_to_frm_location_id) {
                const matchingAccount = accounts.filter(x => x.unique_identifier === eventAdministrator.location.account.unique_identifier); // [0]?.external_ghl_site_url
                const linkOutBase = matchingAccount.length ? matchingAccount[0].external_ghl_site_url : null;
                if (linkOutBase) {
                  linkOutToFRMWithURL = `${linkOutBase}/v2/location/${eventAdministrator.location.private_data.linked_to_frm_location_id}/contacts/detail/${eventAdministrator.public_data.latest_highlevel_contact_id}`
                }
              }
            } catch (e) {
              // This is all a little temporary and there's lots of unverified TypeScript stuff here, so just swallowing any possible errors for now.
            }

            return <tr key={`event-administrator-${eventAdministrator.unique_identifier}`} data-unique-identifier={eventAdministrator.unique_identifier}>
              <td>
                {formatLeadItemDate(eventAdministrator.inserted_at)}
              </td>
              {isAdmin && <td>
                {eventAdministrator.location.account.name || 'Unknown'}
              </td>}
              <td>
                {eventAdministrator.inserted_by_partner?.name || 'Invalid Partner'}
              </td>
              <td>
                <Link to={`/service-locations/${eventAdministrator.location.unique_identifier}`}>
                  {eventAdministrator.location.name || 'Unknown'}
                </Link>
                {!eventAdministrator.location.private_data?.linked_to_frm_location_id ? <span className='ms-1'>{`(!)`}</span> : <></>}
              </td>
              <td>
                {eventAdministrator.event.event_name || 'Unknown'}
              </td>
              <td>
                {eventAdministrator.person_full_name || 'Unknown'}
              </td>
              <td>
                {linkOutToFRMWithURL ? <div>
                  <a href={linkOutToFRMWithURL} target='_blank' rel='noreferrer'>
                    View in FRM
                  </a>
                </div> : <>
                  <SyncLeadButton uniqueIdentifier={eventAdministrator.unique_identifier} />
                </>}
              </td>
            </tr>
          })}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  </div>

}

function EventCheckInRecordsTable() {

  const {
    currentUser
  } = useAuth()

  const {
    accounts
  } = useLeadDetail()

  const isAdmin = !!currentUser?.is_admin

  const [showLeadsRegardlessofGHLStatus, setShowLeadsRegardlessofGHLStatus] = useState(isAdmin ? false : true)

  const [filteringByLocationUniqueIdentifier, setFilteringByLocationUniqueIdentifier] = useState<string | null>(null)

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState<string | null>(null);

  const [dashboardResponse, setDashboardResponse] = useState<DashboardEventCheckInRecordsData | null>(null)

  const loadList = useCallback(async () => {
    // We reload on viewingTab for now
    setErrorLoading(null)
    setLoading(true)
    try {
      const result = await getAllEventCheckInRecords({
        limit: 100,
        showLeadsRegardlessofGHLStatus,
        filteringByLocationUniqueIdentifier
      })
      setDashboardResponse(result)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load leads'
      setErrorLoading(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [showLeadsRegardlessofGHLStatus, filteringByLocationUniqueIdentifier])

  useEffect(() => {
    loadList()
  }, [loadList])

  if (loading) {
    return <div className='p-10'>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  if (errorLoading) {
    return <div className='p-10'>
      <div className="alert alert-danger" role="alert">
        {errorLoading}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={loadList}>
          Retry
        </button>
      </div>
    </div>
  }

  return <div>
    <LeadHeader
      title={dashboardResponse?.total_event_check_in_records === 1 ? '1 Event Check-In Record' : `${dashboardResponse?.total_event_check_in_records} Event Check-In Records`}
      subtitle={!isAdmin ? '' : 'Only showing leads that have not been synced to the 360'}
      showLeadsRegardlessofGHLStatus={showLeadsRegardlessofGHLStatus}
      setShowLeadsRegardlessofGHLStatus={setShowLeadsRegardlessofGHLStatus}
      setFilteringByLocation={setFilteringByLocationUniqueIdentifier}
    />
    <div className='px-10'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-150px'>When Created?</th>
            {isAdmin && <th className='min-w-150px'>Account</th>}
            <th className='min-w-150px'>Partner</th>
            <th className='min-w-150px'>Location</th>
            <th className='min-w-150px'>Event</th>
            <th className='min-w-150px'>Person</th>
            <th className='min-w-150px'>FRM</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {dashboardResponse?.event_check_in_records.map((eventCheckInRecord) => {

            let linkOutToFRMWithURL;
            try {
              if (eventCheckInRecord.public_data.latest_highlevel_contact_id && eventCheckInRecord.location?.private_data?.linked_to_frm_location_id) {
                const matchingAccount = accounts.filter(x => x.unique_identifier === eventCheckInRecord.location.account.unique_identifier); // [0]?.external_ghl_site_url
                const linkOutBase = matchingAccount.length ? matchingAccount[0].external_ghl_site_url : null;
                if (linkOutBase) {
                  linkOutToFRMWithURL = `${linkOutBase}/v2/location/${eventCheckInRecord.location.private_data.linked_to_frm_location_id}/contacts/detail/${eventCheckInRecord.public_data.latest_highlevel_contact_id}`
                }
              }
            } catch (e) {
              // This is all a little temporary and there's lots of unverified TypeScript stuff here, so just swallowing any possible errors for now.
            }

            return <tr key={`event-administrator-${eventCheckInRecord.unique_identifier}`} data-unique-identifier={eventCheckInRecord.unique_identifier}>
              <td>
                {formatLeadItemDate(eventCheckInRecord.inserted_at)}
              </td>
              {isAdmin && <td>
                {eventCheckInRecord.location.account.name || 'Unknown'}
              </td>}
              <td>
                {eventCheckInRecord.inserted_by_partner?.name || 'Invalid Partner'}
              </td>
              <td>
                <Link to={`/service-locations/${eventCheckInRecord.location.unique_identifier}`}>
                  {eventCheckInRecord.location.name || 'Unknown'}
                </Link>
                {!eventCheckInRecord.location.private_data?.linked_to_frm_location_id ? <span className='ms-1'>{`(!)`}</span> : <></>}
              </td>
              <td>
                {eventCheckInRecord.event.event_name || 'Unknown'}
              </td>
              <td>
                {eventCheckInRecord.visitor_full_name || 'Unknown'}
              </td>
              <td>
                {linkOutToFRMWithURL ? <div>
                  <a href={linkOutToFRMWithURL} target='_blank' rel='noreferrer'>
                    View in FRM
                  </a>
                </div> : <>
                  <SyncLeadButton uniqueIdentifier={eventCheckInRecord.unique_identifier} />
                </>}
              </td>
            </tr>
          })}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  </div>

}

function DirectMailRepliesTable() {

  const {
    currentUser
  } = useAuth()

  const {
    accounts
  } = useLeadDetail()

  const isAdmin = !!currentUser?.is_admin

  const [showLeadsRegardlessofGHLStatus, setShowLeadsRegardlessofGHLStatus] = useState(isAdmin ? false : true)

  const [filteringByLocationUniqueIdentifier, setFilteringByLocationUniqueIdentifier] = useState<string | null>(null)

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState<string | null>(null);

  const [dashboardResponse, setDashboardResponse] = useState<DashboardDirectMailRepliesData | null>(null)

  const loadList = useCallback(async () => {
    // We reload on viewingTab for now
    setErrorLoading(null)
    setLoading(true)
    try {
      const result = await getAllDirectMailReplies({
        limit: 100,
        showLeadsRegardlessofGHLStatus,
        filteringByLocationUniqueIdentifier
      })
      setDashboardResponse(result)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load leads'
      setErrorLoading(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [showLeadsRegardlessofGHLStatus, filteringByLocationUniqueIdentifier])

  useEffect(() => {
    loadList()
  }, [loadList])

  if (loading) {
    return <div className='p-10'>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  if (errorLoading) {
    return <div className='p-10'>
      <div className="alert alert-danger" role="alert">
        {errorLoading}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={loadList}>
          Retry
        </button>
      </div>
    </div>
  }

  return <div>
    <LeadHeader
      title={dashboardResponse?.total_direct_mail_replies === 1 ? '1 Direct Mail Reply' : `${dashboardResponse?.total_direct_mail_replies} Direct Mail Replies`}
      subtitle={!isAdmin ? '' : 'Only showing leads that have not been synced to the 360'}
      showLeadsRegardlessofGHLStatus={showLeadsRegardlessofGHLStatus}
      setShowLeadsRegardlessofGHLStatus={setShowLeadsRegardlessofGHLStatus}
      setFilteringByLocation={setFilteringByLocationUniqueIdentifier}
    />
    <div className='px-10'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-150px'>When Created?</th>
            {isAdmin && <th className='min-w-150px'>Account</th>}
            <th className='min-w-150px'>Partner</th>
            <th className='min-w-150px'>Location</th>
            <th className='min-w-150px'>Person</th>
            <th className='w-150px'>Image</th>
            <th className='min-w-150px'>FRM</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {dashboardResponse?.direct_mail_replies.map((directMailReply) => {

            let linkOutToFRMWithURL;
            try {
              if (directMailReply.public_data.latest_highlevel_contact_id && directMailReply.location.private_data.linked_to_frm_location_id) {
                const matchingAccount = accounts.filter(x => x.unique_identifier === directMailReply.location.account.unique_identifier); // [0]?.external_ghl_site_url
                const linkOutBase = matchingAccount.length ? matchingAccount[0].external_ghl_site_url : null;
                if (linkOutBase) {
                  linkOutToFRMWithURL = `${linkOutBase}/v2/location/${directMailReply.location.private_data.linked_to_frm_location_id}/contacts/detail/${directMailReply.public_data.latest_highlevel_contact_id}`
                }
              }
            } catch (e) {
              // This is all a little temporary and there's lots of unverified TypeScript stuff here, so just swallowing any possible errors for now.
            }

            return <tr key={`event-administrator-${directMailReply.unique_identifier}`} data-unique-identifier={directMailReply.unique_identifier}>
              <td>
                {formatLeadItemDate(directMailReply.inserted_at)}
              </td>
              {isAdmin && <td>
                {directMailReply.location.account.name || 'Unknown'}
              </td>}
              <td>
                {directMailReply.inserted_by_partner?.name || 'Invalid Partner'}
              </td>
              <td>
                <Link to={`/service-locations/${directMailReply.location.unique_identifier}`}>
                  {directMailReply.location.name || 'Unknown'}
                </Link>
                {!directMailReply.location.private_data?.linked_to_frm_location_id ? <span className='ms-1'>{`(!)`}</span> : <></>}
              </td>
              <td>
                {directMailReply.public_data.respondent_person?.full_name || 'Unknown'}
              </td>
              <td>
                {directMailReply.public_data.image_urls?.length > 0 ? <a href={
                  directMailReply.public_data.image_urls[0]
                } target='_blank' rel='noreferrer'>
                  View Image
                </a> : 'No Image'}
              </td>
              <td>
                {linkOutToFRMWithURL ? <div>
                  <a href={linkOutToFRMWithURL} target='_blank' rel='noreferrer'>
                    View in FRM
                  </a>
                </div> : <>
                  <SyncLeadButton uniqueIdentifier={directMailReply.unique_identifier} />
                </>}
              </td>
            </tr>
          })}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  </div>

}

function LeadList({ children }: { children: React.ReactNode }) {

  const {
    accounts,
    loadingAccounts,
  } = useAccounts()

  if (loadingAccounts) {
    return <div className='mt-5'>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  return <LeadDetailContext.Provider value={{
    accounts
  }}>
    {children}
  </LeadDetailContext.Provider>
}

export function DebugAllLeads() {

  const { pathname } = useLocation()

  return (
    <>
      {/* Manage Premier Preneed */}
      <MogulHeader title={`All Leads`} />
      <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
        <div className='col-xxl-12'>
          <div className="header-menu align-items-stretch mb-5">
            <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
              <div className="menu-item me-lg-1">
                <Link className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-leads/event-administrators` ? "active" : ""].join(" ")}
                  to={`/debugging/all-leads/event-administrators`}
                >
                  <span className="menu-title">
                    Event Administrators
                  </span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <Link
                  className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-leads/event-check-in-records` ? "active" : ""].join(" ")}
                  to={`/debugging/all-leads/event-check-in-records`}
                >
                  <span className="menu-title">
                    Event Check-In Records
                  </span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <Link
                  className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-leads/direct-mail-replies` ? "active" : ""].join(" ")}
                  to={`/debugging/all-leads/direct-mail-replies`}
                >
                  <span className="menu-title">
                    Direct Mail Replies
                  </span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <Link
                  className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-leads/passare-acquaintances` ? "active" : ""].join(" ")}
                  to={`/debugging/all-leads/passare-acquaintances`}
                >
                  <span className="menu-title">
                    Passare Leads
                  </span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <Link
                  className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-leads/full-circle-leads` ? "active" : ""].join(" ")}
                  to={`/debugging/all-leads/full-circle-leads`}
                >
                  <span className="menu-title">
                    FCA Leads
                  </span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <Link
                  className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-leads/abandoned-carts` ? "active" : ""].join(" ")}
                  to={`/debugging/all-leads/abandoned-carts`}
                >
                  <span className="menu-title">
                    Abandoned Carts
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            <Routes>
              <Route path='event-administrators' element={<LeadList>
                <EventAdministratorTable />
              </LeadList>} />
              <Route path='event-check-in-records' element={<LeadList>
                <EventCheckInRecordsTable />
              </LeadList>} />
              <Route path='direct-mail-replies' element={<LeadList>
                <DirectMailRepliesTable />
              </LeadList>} />
              <Route path='passare-acquaintances' element={<LeadList>
                <PassareAcquaintancesTable />
              </LeadList>} />
              <Route path='full-circle-leads' element={<LeadList>
                <FullCircleLeadsTable />
              </LeadList>} />
              <Route path='abandoned-carts' element={<LeadList>
                <AbandonedCartsTable />
              </LeadList>} />
              <Route path='*' element={<Navigate to='/debugging/all-leads/event-administrators' />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}