import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Link, Navigate, Route, Routes, useLocation, useParams } from "react-router-dom"

import { PageTitle } from "../../_metronic/layout/core"
import { getAccountByUniqueIdentifier, Account, disableAccountLinkToFRM, reInviteAccountUserMembership, removeAccountUserMembership, SimplifiedAccountUserMembership, AccountUserMembership } from "../core/_requests"
import { APIError, useAuth } from "../../app/modules/auth"
import { KTIcon } from "../../_metronic/helpers"
import useAccountMemberships from "../hooks/useAccountMemberships"
import ServiceLocationManageLocations from "./service-locations/detail/ServiceLocationManageLocations"
import MogulHeader from "../components/MogulHeader"
import AccountWarnings from "./service-locations/detail/AccountWarnings"
import { useFrmAuthenticationURL } from "../hooks/useFrmAuthenticationURL"

type AccountContextProps = {
    account: Account,
    myAccountMembership: SimplifiedAccountUserMembership | null, // Note that this is null if the user is an admin, so always check for that
}

const AccountContext = createContext<AccountContextProps>({
    account: {} as Account,
    myAccountMembership: null,
})

export const useAccountDetail = () => {
    return useContext(AccountContext)
}

const DisableLinkButton = ({
    frmAuthenticationURL,
    accountUniqueId,
    onSuccessfullyDisabled
}: {
    frmAuthenticationURL: string,
    accountUniqueId: string,
    onSuccessfullyDisabled: () => void
}) => {

    const [confirmingDisableLink, setConfirmingDisableLink] = useState(false)

    const [disablingLinkToFRM, setDisablingLinkToFRM] = useState(false)
    const [errorDisablingLinkToFRM, setErrorDisablingLinkToFRM] = useState<string | null>(null)

    const disableLinkToFRM = useCallback(async () => {
        if (!accountUniqueId) {
            return
        }
        setDisablingLinkToFRM(true)
        setErrorDisablingLinkToFRM(null)
        try {
            await disableAccountLinkToFRM(accountUniqueId)
            onSuccessfullyDisabled()
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to disable link to FRM'
            setErrorDisablingLinkToFRM(errorMessage)
        } finally {
            setDisablingLinkToFRM(false)
        }
    }, [accountUniqueId, onSuccessfullyDisabled])

    return <div>
        {!confirmingDisableLink ? <>
            <a href={frmAuthenticationURL} className='btn btn-secondary btn-sm me-3'>
                Re-Link to FRM
            </a>
            <button className='btn btn-danger btn-sm' onClick={() => setConfirmingDisableLink(true)}>
                Disable Link to FRM
            </button>
        </> : <>
            <div className='alert alert-info' role='alert'>
                Are you sure you want to disable the link to FRM? This is permanent.
            </div>
            <div className='mt-5'>
                <button className='btn btn-secondary btn-sm' onClick={() => setConfirmingDisableLink(false)}>
                    Cancel
                </button>
                <button className='btn btn-danger btn-sm ms-3' onClick={() => disableLinkToFRM()} disabled={disablingLinkToFRM}>
                    {disablingLinkToFRM ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : 'Yes, Disable Link to FRM'}
                </button>
            </div>
            {errorDisablingLinkToFRM && <div className='mt-5'>
                <div className="alert alert-danger" role="alert">
                    {errorDisablingLinkToFRM}
                </div>
            </div>}
        </>}
    </div>
}

function AccountUserMemberships({
    accountUniqueIdentifier,
    onlyRole,
    canManage = false,
    locationsCanBeManaged = false,
}: { accountUniqueIdentifier: string, onlyRole: 'account_admin' | 'marketer', canManage?: boolean, locationsCanBeManaged?: boolean }) {
    const {
        accountMemberships,
        loading,
        errorMessage,
    } = useAccountMemberships({
        accountUniqueIdentifier,
        onlyRole,
    })

    const [successfullyReinvitedMessage, setSuccessfullyReinvitedMessage] = useState<string | null>(null)
    const [errorReinviting, setErrorReinivting] = useState<string | null>(null)

    const onReinvite = useCallback(async (accountMembership: AccountUserMembership) => {
        setErrorReinivting(null)
        setSuccessfullyReinvitedMessage(null)
        try {
            await reInviteAccountUserMembership(accountMembership.unique_identifier)
            setSuccessfullyReinvitedMessage(`Successfully send an email invite to ${accountMembership.user.first_name} ${accountMembership.user.last_name}`)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to re-invite user'
            setErrorReinivting(errorMessage)
        }
    }, [])

    if (loading) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorMessage) {
        return <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>
    }

    if (!accountMemberships.length) {
        return <div>
            <h4>
                {onlyRole === 'account_admin' ? 'Account Admins' : 'Marketers'}
            </h4>
            <div className="alert alert-info" role="alert">
                {onlyRole === 'account_admin' ? 'No account admins are associated with this account.' : 'No marketers are associated with this account.'}
            </div>
        </div>
    }

    return <div>
        <div>
            <h4>
                {onlyRole === 'account_admin' ? 'Account Admins' : 'Marketers'}
            </h4>
            {successfullyReinvitedMessage && <div className='alert alert-success' role='alert'>
                {successfullyReinvitedMessage}
            </div>}
            {errorReinviting && <div className='alert alert-danger' role='alert'>
                {errorReinviting}
            </div>}
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th scope='col' className='fw-bold'>Name</th>
                        <th scope='col' className='fw-bold'>Email</th>
                        <th scope='col' className='fw-bold'></th>
                    </tr>
                </thead>
                <tbody>
                    {accountMemberships.map((accountMembership, index) => {
                        return <tr key={index}>
                            <td>
                                {`${accountMembership.user.first_name} ${accountMembership.user.last_name}`}
                            </td>
                            <td>
                                {accountMembership.user.email}
                            </td>
                            <td>
                                {canManage && <div className='d-flex justify-content-end'>
                                    <button className='btn btn-secondary btn-sm' onClick={() => onReinvite(accountMembership)}>
                                        Send Email Invite
                                    </button>
                                    {canManage && <RemoveMembershipButton
                                        accountMembership={accountMembership}
                                        onSuccessfullyRemoved={() => {
                                            window.location.reload()
                                        }}
                                    />}
                                    {locationsCanBeManaged && <>
                                        <Link
                                            to={`/accounts/${accountUniqueIdentifier}/memberships/${accountMembership.unique_identifier}`}
                                            className='btn btn-secondary btn-sm ms-3'
                                        >
                                            Manage Locations
                                        </Link>
                                    </>}
                                </div>}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </div>

}

function RemoveMembershipButton({
    accountMembership,
    onSuccessfullyRemoved
}: {
    accountMembership: AccountUserMembership,
    onSuccessfullyRemoved: () => void
}) {
    const [confirmingRemoveMembership, setConfirmingRemoveMembership] = useState(false)

    const [removingMembership, setRemovingMembership] = useState(false)
    const [errorRemovingMembership, setErrorRemovingMembership] = useState<string | null>(null)

    const removeMembership = useCallback(async () => {
        setRemovingMembership(true)
        setErrorRemovingMembership(null)
        try {
            // Remove membership ... call removeAccountUserMembership
            await removeAccountUserMembership(accountMembership.unique_identifier)
            onSuccessfullyRemoved()
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to remove membership'
            setErrorRemovingMembership(errorMessage)
        } finally {
            setRemovingMembership(false)
        }
    }, [onSuccessfullyRemoved])

    return <div className='ms-3'>
        {!confirmingRemoveMembership ? <>
            <button className='btn btn-danger btn-sm' onClick={() => setConfirmingRemoveMembership(true)}>
                Remove
            </button>
        </> : <>
            <div className='alert alert-info' role='alert'>
                Are you sure you want to remove this user from the account? This is permanent.
            </div>
            <div className='mt-5'>
                <button className='btn btn-secondary btn-sm' onClick={() => setConfirmingRemoveMembership(false)}>
                    Cancel
                </button>
                <button className='btn btn-danger btn-sm ms-3' onClick={() => removeMembership()} disabled={removingMembership}>
                    {removingMembership ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : 'Yes, Remove'}
                </button>
            </div>
            {errorRemovingMembership && <div className='mt-5'>
                <div className="alert alert-danger" role="alert">
                    {errorRemovingMembership}
                </div>
            </div>}
        </>}
    </div>
}

function AccountDetailAdmin() {
    const {
        account_unique_identifier: accountUniqueId
    } = useParams<{ account_unique_identifier: string }>()

    const {
        currentUser
    } = useAuth()

    const {
        account,
        myAccountMembership
    } = useAccountDetail()

    const canManage = currentUser?.is_admin || myAccountMembership?.public_data.role === 'account_admin'

    const apiDocumentationURL = useMemo(() => {
        if (process.env.NODE_ENV === 'development') {
            return 'http://localhost:3000';
        }
        return "https://docs.mogulbridge.com"
    }, [])

    const frmAuthenticationURL = useFrmAuthenticationURL({
        accountUniqueId
    })

    return <div>
        {accountUniqueId && <div className='mt-5'>
            <AccountUserMemberships
                accountUniqueIdentifier={accountUniqueId}
                onlyRole="account_admin"
                canManage={canManage}
            />
        </div>}
        {canManage && <div className='mt-3'>
            <button
                className='btn btn-secondary btn-sm'
                data-bs-toggle='modal'
                data-bs-target='#modal_add_user'
            >
                <KTIcon iconName='plus' className='fs-2 text-primary me-0 me-md-2' />
                <span className='d-none d-md-inline'>
                    {`Add a User to ${account.name}`}
                </span>
            </button>
        </div>}
        <div className='mt-3'>
            <MarketerManagement />
        </div>
        {accountUniqueId && <div className="mt-5">
            {!account.private_data?.linked_to_frm_company_id ? <a href={frmAuthenticationURL} className='btn btn-primary btn-lg'>
                Link to FRM
            </a> : <>
                <div>
                    Linked to FRM Company ID: <strong>{account.private_data.linked_to_frm_company_id}</strong>
                </div>
                <div className='mt-3'>
                    {frmAuthenticationURL && <DisableLinkButton
                        frmAuthenticationURL={frmAuthenticationURL}
                        accountUniqueId={accountUniqueId}
                        onSuccessfullyDisabled={() => {
                            window.location.reload()
                        }}
                    />}
                </div>
            </>}
        </div>}
    </div>
}

function MarketerManagement() {
    const {
        currentUser
    } = useAuth()

    const {
        account,
        myAccountMembership
    } = useAccountDetail()

    const canManage = currentUser?.is_admin || myAccountMembership?.public_data.role === 'account_admin'

    return <div>
        <div className="separator separator-dashed my-7"></div>
        {account.unique_identifier && <div className='mt-5'>
            <AccountUserMemberships
                accountUniqueIdentifier={account.unique_identifier}
                onlyRole="marketer"
                canManage={canManage}
                locationsCanBeManaged={true}
            />
        </div>}
        {canManage && <button
            className='btn btn-secondary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#modal_add_marketer'
        >
            <KTIcon iconName='plus' className='fs-2 text-primary me-0 me-md-2' />
            <span className='d-none d-md-inline'>
                {`Add a Marketer to ${account.name}`}
            </span>
        </button>}
        <div className="separator separator-dashed my-7"></div>
    </div>
}

function AccountDetailUser() {

    const {
        account,
        myAccountMembership
    } = useAccountDetail()

    const canManage = myAccountMembership?.public_data.role === 'account_admin'

    return <div>
        {canManage && <div className='mt-5'>
            <AccountUserMemberships
                accountUniqueIdentifier={account.unique_identifier}
                onlyRole="account_admin"
                canManage={false}
            />
        </div>}
        <div className='mt-3'>
            <MarketerManagement />
        </div>
    </div>
}

function AccountsWrapperRoutes() {

    const {
        account_unique_identifier: accountUniqueId
    } = useParams<{ account_unique_identifier: string }>()

    const {
        currentUser
    } = useAuth()

    const { pathname } = useLocation()

    const [account, setAccount] = useState<Account | null>(null);
    const [myAccountMembership, setMyAccountMembership] = useState<SimplifiedAccountUserMembership | null>(null);
    const [loadingAccount, setLoadingAccount] = useState(true);
    const [errorLoadingAccount, setErrorLoadingAccount] = useState<string | null>(null);

    const loadAccount = useCallback(async () => {
        if (!currentUser) {
            return
        }
        if (!accountUniqueId) {
            return
        }
        setLoadingAccount(true)
        setErrorLoadingAccount(null)
        try {
            const {
                account: updatedAccount,
                myAccountMembership: updatedMyAccountMembership,
            } = await getAccountByUniqueIdentifier(accountUniqueId)
            setAccount(updatedAccount)
            setMyAccountMembership(updatedMyAccountMembership)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load account'
            setErrorLoadingAccount(errorMessage)
        } finally {
            setLoadingAccount(false)
        }
    }, [accountUniqueId, currentUser])

    useEffect(() => {
        loadAccount()
    }, [loadAccount])

    if (!currentUser || loadingAccount) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingAccount || !account) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorLoadingAccount || 'Failed to load account'}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadAccount}>
                    Retry
                </button>
            </div>
        </div>
    }

    return (
        <AccountContext.Provider value={{
            account,
            myAccountMembership
        }}>
            {/* Manage Premier Preneed */}
            <MogulHeader title={`Manage ${account.name}`} />
            <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
                <div className='col-xxl-12'>
                    <div className="header-menu align-items-stretch mb-5">
                        <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                            <div className="menu-item me-lg-1">
                                <Link className={["menu-link py-3 btn btn-link-primary", pathname === `/accounts/${account.unique_identifier}/details` ? "active" : ""].join(" ")}
                                    to={`/accounts/${accountUniqueId}`}
                                >
                                    <span className="menu-title">
                                        Manage Users
                                    </span>
                                </Link>
                            </div>
                            <div className="menu-item me-lg-1">
                                <Link
                                    className={["menu-link py-3 btn btn-link-primary", pathname === `/accounts/${account.unique_identifier}/warnings` ? "active" : ""].join(" ")}
                                    to={`/accounts/${accountUniqueId}/warnings`}
                                >
                                    <span className="menu-title">
                                        View Warnings
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className={`card card-xxl-stretch mb-5 mb-xl-8 px-10 py-5`}>
                        <Routes>
                            <Route path='warnings' element={<AccountWarnings />} />
                            {currentUser.is_admin ? <>
                                <Route path='admin' element={<AccountDetailAdmin />} />
                                <Route path='memberships/:account_membership_unique_identifier' element={<ServiceLocationManageLocations />} />
                                <Route path='*' element={<Navigate to={`/accounts/${accountUniqueId}/admin`} replace />} />
                            </> : <>
                                <Route path='details' element={<AccountDetailUser />} />
                                {myAccountMembership?.public_data.role === 'account_admin' && <>
                                    <Route path='memberships/:account_membership_unique_identifier' element={<ServiceLocationManageLocations />} />
                                </>}
                                <Route path='*' element={<Navigate to={`/accounts/${accountUniqueId}/details`} replace />} />
                            </>}
                        </Routes>
                    </div>
                </div>
            </div>
        </AccountContext.Provider>
    )
}

export default AccountsWrapperRoutes