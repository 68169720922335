import { useMemo } from "react"

export const useFrmAuthenticationURL = ({ accountUniqueId }: { accountUniqueId?: string }) => {

    const frmAuthenticationURL = useMemo(() => {
        if (!accountUniqueId) {
            return undefined
        }
        //
        // Note if you change this you need to
        // update https://marketplace.gohighlevel.com/apps/659c414c2efc3b0db688fd94/settings
        // to match the Selected Scopes
        //
        const highlevelScopeString = [
            'locations.write',
            'locations.readonly',
            'oauth.write',
            'oauth.readonly',
            'contacts.write',
            'contacts.readonly',
            'locations/customFields.write',
            'locations/customFields.readonly',
            'locations/customValues.write',
            'locations/customValues.readonly',
            'forms.readonly',
            'forms.write',
            'conversations.readonly',
            'conversations.write',
            'conversations/message.readonly',
            'conversations/message.write',
            'opportunities.write',
            'opportunities.readonly',
            'medias.readonly',
            'medias.write',
        ].join(' ')
        const highlevelClientID = `659c414c2efc3b0db688fd94-lr59pui6`
        const redirectURI = `${window.location.origin}/auth/frm/oauth/callback`
        return `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirectURI}&client_id=${highlevelClientID}&scope=${highlevelScopeString}&state=${accountUniqueId}`
    }, [accountUniqueId])

    return frmAuthenticationURL
}