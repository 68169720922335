import { Route, Routes } from "react-router-dom"

import PassareOrganizationDetail from "./passare/detail/PassareOrganizationDetail"
import PassareCaseDetail from "./passare/detail/PassareCaseDetail"

function PassareRoutes() {
    return (
        <>
            <Routes>
                <Route path='organizations/:passare_organization_unique_identifier/*' element={<PassareOrganizationDetail />} />
                <Route path='cases/:passare_case_unique_identifier/*' element={<PassareCaseDetail />} />
            </Routes>
        </>
    )
}

export default PassareRoutes